* {
  box-sizing: border-box;
}

body {
  position: relative;
  height: 100vh;
  font-family: 'Lato', 'poppins', sans-serif;
}

/* General Style */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.item {
  position: relative;
  width: 100%;
  padding: 15px;
  align-items: stretch;
  flex: 0 0 50%;
  max-width: 50%;
}

/* Header Section */
header {
  background: white;
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  position: fixed;
  box-shadow: 0px 4px 7px 2px rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  left: 0;
}

.container {
  display: flex;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.header {
  justify-content: space-between;
}

header .logo {
  font-size: 30px;
  margin: 0;
  margin-right: 0px;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

header .logo img {
  max-height: 40px;
  max-width: 100%;
  height: auto;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  background-color: #fff;
  color: #333;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  /* width: 75%; */
}

/* .nav-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu>ul>li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
} */

.home,
.about,
.services,
.contact {
  margin: 0 10px;
  padding: 5px 20px;
  color: #333;
  transition: 0.3s;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.nav-links>a:hover{
  background-color: #FF9A02;
  color: #fff;
}

.menu-icon {
  display: none;
}

/* Hero Section */
section {
  padding: 0;
  overflow: hidden;
}

#hero {
  width: 100%;
  height: 80vh;
  background: #FFF2DE;
}

.container-hero {
  width: 100%;
  max-width: 1140px;
  padding-top: 72px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.left-hero {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 50%;
  max-width: 50%;
  order: 1;
  position: relative;
  width: 100%;
  padding-top: 0;
  padding-right: 15px;
  padding-left: 15px;
  transition-duration: 1s;
  transition-timing-function: ease;
  opacity: 1;
  transform: translateZ(0);
  transition-delay: .2s;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: 700;
  /* line-height: 56px; */
  color: #FF9A03;
}

#hero h2 {
  color: #333;
  margin-bottom: 50px;
  font-size: 18px;
}

.btn {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 7px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #FF9A03;
}

.btn-contact {
  font-size: 16px;
}

.right-hero {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  order: 2;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease;
  opacity: 1;
  transform: translateZ(0) scale(1);
  transition-delay: .2s;

}

.img-hero {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* Services Section */
#services {
  display: block;
  padding: 30px 0;
  overflow: hidden;
}

.container-ser {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.section-title {
  text-align: center;
  /* padding-bottom: 30px; */
}

.section-title h2 {
  font-size: 32px;
  font-weight: 900;
  line-height: 48px;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  color: #FF9A02;
  ;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #FF9A02;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  text-align: justify;
  line-height: 20px;
  padding-left: 5px;
  padding-right: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  box-shadow: 4px 8px 15px 5px rgba(0, 0, 0, 0.1);
}

.icon-post h4 {
  text-align: left;
  font-weight: 900;
}

#services .icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 4px 8px 15px 5px rgba(0, 0, 0, 0.2);
}

/* About Section */
.img-about {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

#about .about-note {
  display: block;
  line-height: 30px;
  padding-top: 30px;
  text-align: justify;
  color: #333;
}

/* Contact Section */
#contact .item-1 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

#contact .item-2 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

#contact .info {
  border-top: 3px solid #FF9A02;
  border-bottom: 3px solid #FF9A02;
  padding: 30px;
  margin-right: 20px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

#contact .info i {
  font-size: 20px;
  color: #FF9A02;
  float: left;
  width: 44px;
  height: 44px;
  /* background: #e7f5fb; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

#contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: -10px;
  color: #333;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

#contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
}

#contact .email-form {
  width: 100%;
  border-top: 3px solid #FF9A02;
  border-bottom: 3px solid #FF9A02;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

#contact .email-form input {
  height: 44px;
}

#contact .email-form .form-group {
  padding-bottom: 8px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

#contact .email-form input,
#contact .email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

#contact .email-form textarea {
  padding: 10px 12px;
}

textarea.form-control {
  height: auto;
}

.form-control {
  display: block;
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  /* height: calc(1.5em + .75rem + 2px); */
  padding: .375rem .75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

button,
input {
  overflow: visible;
}

input[type="submit"] {
  margin-top: 1rem;
  cursor: pointer;
  background: #FF9A02;
  color: white;
  border: 2px solid #FF9A02;
}

input[type="submit"]:hover{
  color: #FF9A02;
  background: #fff;
  border: 2px solid #FF9A02;
}

/* Footer Section */
#footer {
  font-size: 14px;
  background: #FF9A02;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px;
  color: #fff;
  text-align: center;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.social-media{
  font-size: 20px;
  color: #fff;
  margin-bottom: 20px;
}

.social-media a{
  color: #ffcf86;
  margin-right: 20px;
}

.social-media a:hover{
  color: #fff;
  transition: 0.5s;
}

/* Screen size: 768px */
@media (max-width: 768px) {
  header{
    padding: 4px 0;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 75px;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }

  .home,
  .about,
  .services,
  .contact {
    color: #333;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-decoration: none;
  }

  .nav-links-mobile>a:hover{
    color: #FF9A02;
  }
  
  .menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: black;
    background-color: #fff;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  #hero{
    height: 100vh;
  }

  .right-hero {
    max-width: 400px;
    flex: 0 0 100%;
    order: 1;
    text-align:center
  }

  .right-hero .img-hero {
    animation: none;
    width: 80%;
  }

  #hero h1{
    font-size: 20px;
    margin: 0;
  }

  #hero h2{
    font-size: 16px;
    margin-bottom: 20px;
  }

.btn{
  font-size: 16px;
  padding: 10px 20px;
}

  .left-hero {
    text-align: center;
    max-width: 400px;
    flex: 0 0 100%;
    order: 2;
  }

  .d-flex {
    justify-content: center;
    margin-top: -15px;
  }

  
.section-title h2 {
  font-size: 28px;
}

  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #333;
    line-height: 20px;
    padding: 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    box-shadow: 4px 8px 15px 5px rgba(0, 0, 0, 0.1);
  }

  .item {
    max-width: 90%;
    flex: none;
    /* padding-left: 5px;
    padding-right: 5px; */
    margin-bottom: 15px;
    text-align: center;
  }

  #contact .info {
    margin-right: 0;
    /* padding: 0 80px; */
  }

  #contact .item-1 {
    flex: none;
    max-width: 80%;
    margin-bottom: 50px;
  }

  #contact .item-2 {
    flex: none;
    max-width: 80%;
  }

  #about .about-note {
    text-align: center;
  }
}